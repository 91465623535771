<template>
  <terms-and-condition></terms-and-condition>
</template>
<script>
import service from "@/services/others";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TermsAndCondition from "./Terms-and-condition.vue";

export default {
  components: {
    TermsAndCondition,
  },
  data() {
    return {
      email: "",
    };
  },
  created() {
    this.email = this.getEmail();
  },
  methods: {
    getEmail() {
      const user = JSON.parse(localStorage.userData);
      return user.email;
    },
    next() {
      this.$router.push("/dashboard");
    },
    resend() {
      service
        .sendEmailPass({ email: this.email }, true)
        .then((response) => {
          console.log(response);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Se ha enviado el link a tu correo.",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error al enviar link al correo.",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>
